<template>
    <div class="content_body">
        <router-link v-if="check('配置参数')" to="/SettingConfig">
            <img src="../assets/images/peizhicanshu.png" alt="">
             医院设置
        </router-link>
        <router-link v-if="check('患者来源')" to="/SettingWard">
            <img src="../assets/images/huanzhelaiyuan.png" alt="">
            患者来源
        </router-link>
<!--        <router-link v-if="check('系统用户')" to="/SettingUser">-->
<!--            <img src="../assets/images/xitongyonghu.png" alt="">-->
<!--            医院账号管理-->
<!--        </router-link>-->
<!--        <router-link v-if="check('角色设置')" to="/SettingRole">-->
<!--            <img src="../assets/images/jueseshezhi.png" alt="">-->
<!--            账号权限管理-->
<!--        </router-link>-->
<!--        <router-link v-if="check('系统说明')" to="/SettingExplain">-->
<!--            <img src="../assets/images/xitongshuoming.png" alt="">-->
<!--            使用说明书-->
<!--        </router-link>-->
        <router-link v-if="check('模块管理')" to="/SettingDoctorSayInfo">
            <img src="../assets/images/yishengjianyi.png" alt="">
            模块管理
        </router-link>
        <router-link v-if="check('检测人员')" to="/InspectionPersonnel">
            <img src="../assets/images/jiancerenyuan.png" alt="">
            检测人员
        </router-link>
      <!--      <router-link v-if="check('患者来源')" to="/SettingWard"><img src="../assets/images/huanzhelaiyuan.png" alt=""></router-link>-->
<!--    <router-link v-if="check('量表帮助')" to="/SettingScale"><img src="../assets/images/liangbiaobangzhu.png" alt=""></router-link>-->

<!--    <router-link v-if="check('测评方案')" to="/SettingPlan"><img src="../assets/images/fanganshezhi.png" alt=""></router-link>-->
    </div>
</template>

<script>
export default {
  name: "SystemSetting",
  data() {
    return {};
  },
  computed: {},
  watch: {},
    mounted() {
        //获取权限列表
        this.$store.dispatch('GetAuth')
    },
  methods: {
      //检测按钮权限
      check(_name) {
          let show = false
          this.$store.state.auth.map(item => {
              if (item.name === this.$store.state.cmenu) {
                  let child = item.child
                  child.map(item2 => {
                      if (_name === item2.name) {
                          show = true
                      }
                  })
              }
          })
          return show
      },
  },
}
</script>

<style scoped>
.content_body {
    padding: 75px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: auto;
}

.content_body a {
    display: block;
    background: #FAFCFF;
    border-radius: 8px;
    width: 486px;
    height: 196px;
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #4D4653;
    text-align: center;
    margin-bottom: 30px;
}

.content_body img {
    display: block;
    width: 100px;
    height: 100px;
    cursor: pointer;
    margin: 20px auto;
}
</style>
